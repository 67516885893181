import useClipboard from 'vue-clipboard3';

const detectVersion = () => {
  const u = navigator.userAgent;
  const ua = u.toLowerCase();
  let isAndroid;
  let isIOS;
  let isIOS9;
  let version;

  if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
    //android终端或者uc浏览器
    //Android系统
    isAndroid = true;
  }

  if (ua.indexOf('like mac os x') > 0) {
    //ios
    const regStr_saf = /os [\d._]*/gi;
    const verInfo = ua.match(regStr_saf);
    version = `${verInfo}`.replace(/[^0-9|_.]/gi, '');
    version = version.replace(/_/gi, '.');
  }

  const version_str = `${version}`;

  if (version_str && version_str !== 'undefined') {
    version = parseInt(version);

    if (version >= 8) {
      // ios9以上
      isIOS9 = true;
    } else {
      isIOS = true;
    }
  }

  return { isAndroid, isIOS, isIOS9 };
};

export default function download() {
  const { toClipboard } = useClipboard();

  const browser = {
    versions: (() => {
      const u = navigator.userAgent;

      return {
        trident: u.indexOf('Trident') > -1,
        presto: u.indexOf('Presto') > -1,
        webKit: u.indexOf('AppleWebKit') > -1,
        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,
        mobile: !!u.match(/AppleWebKit.*Mobile.*/),
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1,
        iPhone: u.indexOf('iPhone') > -1,
        iPad: u.indexOf('iPad') > -1,
        webApp: u.indexOf('Safari') == -1,
        souyue: u.indexOf('souyue') > -1,
        superapp: u.indexOf('superapp') > -1,
        weixin: u.toLowerCase().indexOf('micromessenger') > -1,
        Safari: u.indexOf('Safari') > -1,
      };
    })(),

    language: (navigator.browserLanguage || navigator.language).toLowerCase(),
  };

  const downloadHandler = async (target, text) => {
    try {
      if (text) {
        await toClipboard(text);
      }

      if (browser.versions.ios) {
        if (target === 'student') {
          top.location.href = 'https://apps.apple.com/cn/app/id1457432232';
        } else if (target === 'company') {
          top.location.href = 'https://apps.apple.com/cn/app/id1457431865';
        } else {
          top.location.href = 'https://apps.apple.com/cn/app/id1457432232';
        }
      } else if (browser.versions.android) {
        if (target === 'student') {
          window.location.href =
            'https://sj.qq.com/myapp/detail.htm?apkName=student.com.lemondm.yixiaozhao';
        } else if (target === 'company') {
          window.location.href =
            'https://sj.qq.com/myapp/detail.htm?apkName=company.com.lemondm.yixiaozhao';
        } else {
          window.location.href =
            'https://sj.qq.com/myapp/detail.htm?apkName=student.com.lemondm.yixiaozhao';
        }
      }
    } catch (error) {
      console.error(error);
    }

    setTimeout(() => {
      if (target === 'student') {
        window.location.href = 'http://download.yxzjob.com/student.html';
      } else if (target === 'company') {
        window.location.href = 'http://download.yxzjob.com/company.html';
      } else {
        window.location.href = 'http://download.yxzjob.com/student.html';
      }
    }, 200);
  };

  const openApp = (target) => {
    // const schemeConfig = {
    //   student: {
    //     ios: '',
    //     android: '',
    //   },
    // };
    // company://yixiaozhao
    // const config = schemeConfig[target] || scheme.student;
    const scheme = `${target}://yixiaozhao`;
    const { isAndroid, isIOS, isIOS9 } = detectVersion();

    const downloader = () => {
      downloadHandler(target);
    };

    if (isAndroid || isIOS9) {
      location.href = scheme;

      setTimeout(downloader, 100);
      setTimeout(() => location.reload(), 1000);
    } else if (isIOS) {
      const delay = 1000;
      let hasApp = true;

      const start = Date.now();
      const iframe = document.createElement('iframe');
      iframe.setAttribute('src', scheme);
      iframe.setAttribute('style', 'display:none');
      document.body.appendChild(iframe);

      setTimeout(() => {
        if (!hasApp) {
          downloader();
        }

        document.body.removeChild(iframe);
      }, delay + 1000);

      setTimeout(() => {
        hasApp = !(Date.now() - start < delay + 100);
      }, delay);
    }
  };

  return {
    downloadHandler,
    browser,
    openApp,
  };
}
