import request from '@/util/request';

function createFunc(url, method) {
  return (option) => {
    return new Promise((resolve, reject) => {
      request({
        url,
        method: method || 'get',
        timeout: 60000,
        ...option,
      })
        .then((res) => resolve(res.result))
        .catch((err) => reject(err));
    });
  };
}

export const getTeachin = createFunc('/api/sys/share/getTeachin');

export const getStudentOnlineResume = createFunc(
  '/api/sys/share/getStudentOnlineResume'
);

export const getBanner = createFunc('/api/sys/share/getBanner');

export const getByUndersId = createFunc('/api/sys/share/getByUndersId');

export const getSchoolByUndersId = createFunc(
  '/api/sys/share/getSchoolByUndersId'
);

export const getIndustryList = createFunc('/sys/sys-dict/getIndustryList');

export const getNatureList = createFunc('/sys/sys-dict/getNatureList');

export const getPosInfoList = createFunc('/sys/sys-dict/getPosInfoList');

export const getAreaList = createFunc('/api/sys/sys-area/getAreaList?type=WEB');

export const getStudentList = createFunc(
  '/api/appcompany/connect-info/getStudentList'
);

export const querySignupList = createFunc('/api/sys/share/querySignupList');

export const getUndersProfession = createFunc(
  '/api/sys/share/getUndersProfession'
);

export const getDstOnlineResume = createFunc(
  '/api/sys/share/getDstOnlineResume'
);

export const querySchoolInfo = createFunc('/sys/sys-school/querySchoolInfo');

export const getJobShare = createFunc('/api/sys/share/getProfession');

export const getSameTypeRecommend = createFunc(
  'api/sys/share/getSameTypeRecommend'
);

export const getComInfo = createFunc('/api/sys/share/getComInfo');

export const getProfessionList = createFunc('/api/sys/share/getProfessionList');

export const getActivityData = createFunc('/api/sys/activity/getActivityData');

export const getActivityCoupon = createFunc(
  '/api/sys/activity/getActivityCoupon',
  'post'
);

export const getMyCouponCodePage = createFunc(
  '/api/sys/activity/getMyCouponCodePage'
);

export const getActivityRankingTop = createFunc(
  '/api/sys/activity/getActivityRankingTop'
);
